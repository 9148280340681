import React from "react"
import { Helmet } from "react-helmet"

const HeadTags = ({ featuredImage, title, siteUrl }) => {
  return (
    <>
      <Helmet>
        <meta name="author" content={title} />
        <meta name="twitter:card" content="summary_large_image" />
        {featuredImage && (
          <meta
            property="og:image"
            content={`${siteUrl}${featuredImage.src}`}
          />
        )}
      </Helmet>
    </>
  )
}

export default HeadTags
