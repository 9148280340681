import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import HeadTags from "../components/HeadTags"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

const BlogPostTemplate = ({ data, pageContext, location }) => {

  const post = data.markdownRemark
  const { title, siteUrl } = data.site.siteMetadata
  const { previous, next } = pageContext
  const featuredImage =
    (post.frontmatter.featuredImage &&
      post.frontmatter.featuredImage.childImageSharp.fluid) ||
    null

  return (
    <Layout location={location} title={title}>
      <SEO
        location={location}
        title={post.frontmatter.title}
        description={post.excerpt}
      />

      <HeadTags featuredImage={featuredImage} title={title} siteUrl={siteUrl} />

      <article>
        <header style={{ borderTop: "1px solid lightgray" }}>
          <h2
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
              fontWeight: "bold",
            }}
          >
            {post.frontmatter.title}
          </h2>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: 0,
              color: "gray",
            }}
          >
            {post.frontmatter.date}
          </p>
        </header>

        {featuredImage && (
          <Img fluid={featuredImage} style={{ marginTop: rhythm(0.75) }} />
        )}

        <br />

        <section
          style={{ fontSize: "1.125rem" }}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <hr style={{ marginBottom: rhythm(1) }} />
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
            marginLeft: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        social {
          twitter
          email
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
